<template>
  <div>
    <CommonSliderDrawer :openDrawer="open">
      <div class="absolute right-0 m-2 flex">
        <button @click="closePanel" type="button"
          class="z-50 text-gray-400 bg-light-silver rounded-full fill-secondary p-1">
          <svg aria-hidden="true" class="w-5 h-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <div
        v-if="route.query.slug == 'everywhere-nurse-service' || (serviceType && serviceType.slug == 'everywhere-nurse-service')"
        class="py-20 px-6 h-full bg-grey-light flex flex-col shadow-[-3px_0px_6px_rgba(0,0,0,0.29)]">
        <h3 class="pb-2">{{ showLocationText }}</h3>
        <h5 class="pb-3">{{ $t("testDetail.everyWhereSubtitle") }}</h5>

        <div v-if="!showClose || !mapSearchText">
          <ClinicMapSearchBox v-if="!isEveryWherePriceLookup" :tabValue="true" :showClose="false" :showSearchBox="true"
            @onCloseClick="onCloseClicked" @onSearchClick="onEverywhereSearchClicked" :isMobile="true"
            :searchTextValue="mapSearchText"></ClinicMapSearchBox>
          <ProductNurseServiceForm class="mt-2" :regionLists="regionLists" @onSubmit="onEverywhereSearchClicked" v-else>
          </ProductNurseServiceForm>
        </div>
        <div class="flex flex-col items-center" v-else>
          <div class="flex px-3.5 w-full mb-8 md:px-4 py-4 border-light-silver bg-white rounded-lg">
            <NuxtImg loading="lazy" src="/icons/nurse-icon-green.svg" class="w-9" alt="clinic icon" width="36"
              height="36" />
            <div class="px-4">
              <div class="flex items-baseline">
                <div class="basis-1/2 grow">
                  <h3 class="cursor-pointer">{{ mapSearchText }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isEveryServicewhereAvailable" class="flex flex-col w-full items-center">
            <div v-if="showTestLists.length > 0" class="w-full">
              <ClinicChosenTestBlock :showTestLists="showTestLists"></ClinicChosenTestBlock>
          <div class="flex px-4 space-x-2"> 
          <NuxtImg loading="lazy" alt="plus" width="9" height="9" src="/icons/plus.svg" />
               <NuxtLink :text="t(`checkoutMultipleTests.addAnother`)"
                class="text-secondary font-semibold flex px-4 self-start my-4 cursor-pointer" @click="onClick('everywhere')">
                <NuxtImg loading="lazy" alt="plus" width="32" height="32" src="/icons/plus.svg" />
          </NuxtLink>
          </div>
              
            </div>
            <BaseButton  v-if="isEveryServicewhereAvailable  && showTestLists.length == 0"
            @click="onClick('everywhere')" :text="$t('testDetail.chooseYourTests')" class="w-full h-12 text-lg mt-8" />
            <BaseButton v-else @click="onClick('checkout')" :text="$t('testDetail.bookAppointment')" class="w-full h-12 text-lg mt-8" />
            <h3 class="my-8">Or</h3>
            <BaseButton variant="outlined" weight="normal" @click="onClick('everywhereChooseClinic')"
              :text="$t('testDetail.anotherLocation')" class="w-[270px] h-12 text-lg bg-white" />
          </div>
          <div v-else class="w-full">
            <h5 class="pb-3" >{{ $t("getStarted.everywhereNurseService.searchErrorDescription") }}</h5> 
            <div class="flex flex-col items-center">
              <BaseButton @click="onClick('chooseClinic')" :text="$t('testDetail.anotherLocation')"
                class="w-[270px] h-12 text-lg bg-white" />
            </div>
            <div class="absolute left-0 w-full border border-light-gray"
              v-if="otherServiceLists && otherServiceLists.length > 0">
              <h2 class="my-8">or</h2>
              <div class="bg-white">
                <h3 class="mr-auto p-4 border border-light-gray">{{ $t("testDetail.selectAnotherService") }}</h3>
                <div class="flex flex-col border-b border-light-gray" v-for="(relatedData, index) in otherServiceLists"
                  :key="index">
                  <div class="text-lg py-1">
                    <ProductRadioButton :id="index" isEverywhere :title="relatedData?.service_type?.title"
                      :slug="relatedData?.slug" :extraText="$formatCurrency(relatedData.price)" v-model="picked"
                      @change="updateRadioAndMove()"></ProductRadioButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-16 px-4 h-full bg-grey-light flex flex-col shadow-[-3px_0px_6px_rgba(0,0,0,0.29)]">
        <h3>{{ $t("checkoutMultipleTests.yourBookingChoice") }}</h3>
        <div class="flex flex-col w-full mt-4">
          <ClinicChosenTestBlock :getClinicDetail="getClinicDetail" :showTestLists="showTestLists">
          </ClinicChosenTestBlock>
          <div class="flex px-4 space-x-2" v-if="showTestLists.length > 0 && checkoutStore.testLists.length < maxTestList"> 
          <NuxtImg loading="lazy" alt="plus" width="9" height="9" src="/icons/plus.svg" />
          <NuxtLink 
            :text="t(`checkoutMultipleTests.addAnother`)"
            class="text-secondary self-start my-4 cursor-pointer font-semibold" @click="onClick('chooseTest')">
          </NuxtLink>
          </div>
        </div>


        <div class="mt-8">
          <BaseButton
            :text="showTestLists.length > 0 ? t(`checkoutMultipleTests.bookNow`) : t(`checkoutMultipleTests.chooseTest`)"
            class="w-full h-12 text-lg" @click="onClick((showTestLists.length > 0)? 'checkout' : 'chooseTest')"></BaseButton>
          <div v-if="checkoutStore.testLists.length < maxTestList && showTestLists.length == 0">
            <h3 class="my-4 text-center">Or</h3>

            <BaseButton variant="outlined" :text="t(`checkoutMultipleTests.findAnother`)" class="w-full bg-white"
              weight="normal" @click="onClick('chooseClinic')"></BaseButton>
            
          </div>
        </div>
        <div class="flex md:flex-row flex-col pt-12" v-if="showTestLists.length == 0">
          <CommonLiveChatBlock :mobile="true" imageClass="w-14"></CommonLiveChatBlock>
        </div>
      </div>

      <div
        class="border-t-2 border-light-silver w-full bg-grey-light absolute bottom-0 p-3 flex justify-end text-secondary underline font-bold">
        <NuxtLink @click="closePanel">{{ $t("common.cancel") }}</NuxtLink>
      </div>
      <!-- /End replace -->
    </CommonSliderDrawer>
  </div>
</template>
<script setup>

import { useGlobalStore } from "@/stores/global";
import { useMapStore } from "@/stores/map";
import { Loader } from "@googlemaps/js-api-loader";
import { useCheckoutStore } from "~/stores/checkout";
const getStartedModalStore = useGetStartedModalStore();
const globalStore = useGlobalStore();
const checkoutStore = useCheckoutStore();
const mapStore = useMapStore();

const { t } = useI18n();
const emit = defineEmits([
  "closeModal", "searchText", "clearSearch", "onTabClick", "chooseTest",
  "onCloseClicked", "closeTestModal", "closeCheckoutModal"
]);

const props = defineProps({
  openDrawer: { type: Boolean, default: false },
  serviceType: { type: Object },
  otherServiceLists: { type: Object },
  startPoint: {
      type: String,
      default: 'get-started',
    },
});

const route = useRoute();
const open = ref(props.openDrawer);
console.log("opened", open)
const picked = ref();
const isMobile = useScreenSize("sm");

// @TODO: Go back and check this feature when available!
const isEveryWherePriceLookup = globalStore.siteEverywhereRegionLookUp;
if (isEveryWherePriceLookup.value) {
  const { data: regionLists } = await useEveryWhereRegionPriceList();
}

const showClose = ref(false);
const isEveryServicewhereAvailable = ref(false);
const isClinicPath = ref(route.path.includes("clinics"));
const isTestsPage = ref(route.path.includes("tests"));
const isGetStartedPage = ref(route.path.includes("get-started"));
const mapSearchText = computed(() => globalStore.siteClinicMapText);
const searchCheckoutClinic = ref(false)
const showTestLists = computed(() => checkoutStore.testLists);
const getClinicDetail = computed(() => mapStore.selectedClinic);
watch(() => props.openDrawer, newData => {
  open.value = newData;
  if (route.query.slug == "everywhere-nurse-service" || props.serviceType?.slug == "everywhere-nurse-service") {
    showClose.value = true;
    isEveryServicewhereAvailable.value = true;
  }else {
    showClose.value = false;
    isEveryServicewhereAvailable.value = false;
  }
}, { immediate: true });

function closeModal() {
  emit("closeModal");
}
/**
 * Asynchronous function triggered when the "Everywhere Search" is clicked.
 * @param {string} searchData - The search data entered by the user.
 */
async function onEverywhereSearchClicked(searchData) {
  searchCheckoutClinic.value = true
  globalStore.setClinicMapSearchText(searchData);
  //mapSearchText.value = searchData;
  const result = await findLatLang(searchData);
  isEveryServicewhereAvailable.value = result;
  showClose.value = true;
}

const maxTestList = checkoutStore.maxTestLists;

function onCloseClicked() {
  emit("onCloseClicked");
}

async function findLatLang(address) {
  const loader = new Loader({
    apiKey: useRuntimeConfig().public.apiGoogleMapKey,
    version: "weekly",
    libraries: ["places"]
  });
  const apiPromise = await loader.load();
  const geoCoder = new apiPromise.maps.Geocoder();
  try {
    const results = await geoCoder.geocode({ address: address });
    const { data: resultChecked } = await useCheckEverywhereService(
      results.results[0].geometry.location.lat(),
      results.results[0].geometry.location.lng()
    );
    return resultChecked.value.available;
  } catch (error) {
  //  console.log('error')
    // return false;
  }
}

const showLocationText = computed(() => {
  if (showClose.value == true && mapSearchText.value !='') {
     if(isEveryServicewhereAvailable.value && !searchCheckoutClinic.value)
     return t("getStarted.everywhereNurseService.searchSuccessTitleInPanelSelected")
    return isEveryServicewhereAvailable.value
    ? t("getStarted.everywhereNurseService.searchSuccessTitleInPanel")
    : t("getStarted.everywhereNurseService.searchErrorTitleInPanel");
  }
  return t("testDetail.serveLocation");
});

function chooseAnotherLocation() {
  globalStore.setClinicMapSearchText('');
  showClose.value = false;
  closeModal();
}

function updateRadioAndMove() {
  navigateTo(picked.value);
}

function closePanel() {
  if (isTestsPage.value) {
    emit("closeCheckoutModal");
  } else {
    emit('closeModal')
  }

}

function onClick(type) {
  try {
    switch (type) {
      case "chooseTest":
        if (isTestsPage.value) {
          emit("chooseTest");
        } else {
          emit('closeModal')
        }
        break;

      case "chooseClinic":
      globalStore.setClinicMapSearchText('')
        showClose.value = false;
        if(isGetStartedPage.value){
          getStartedModalStore.navigate('select_clinic');
        }
      
          emit('closeModal')
          return navigateTo({
            path: route.path,
            query: {
              section: route.query.section,
              service: route.query.service
            }
          });
        

      case "everywhereChooseClinic":
        globalStore.setClinicMapSearchText('')
        showClose.value = false;
        isEveryServicewhereAvailable.value = false;
        return navigateTo({
          path: route.path,
          query: {
            section: route.query.section,
            service: route.query.service
          }
        });

      case "everywhere":
        if (isTestsPage.value) {
          emit("chooseTest");
        } else {
          emit('closeModal')
        }
       
        const everywhereQuery = { query: { slug: "everywhere-nurse-service" } };
      //  checkoutStore.setSelectedClinicId("everywhere");
        return navigateTo(localeUrlQuery(everywhereQuery));

      case 'checkout':
     const testListsId = showTestLists.value.map((data, index) => data.id);
     const queryUpdated = { clinic_id: getClinicDetail.value.id, testListsId }
   
     const clinicId= (route.params.service == "everywhere-nurse-service" || props.serviceType?.slug == "everywhere-nurse-service" ) ? 'everywhere' : getClinicDetail.value.id
    

    //  checkoutStore.setCardSelectedTests(showTestLists.value);
    //  checkoutStore.setCardSelectedClinic(getClinicDetail.value);
     checkoutStore.setSelectedClinicCookieData(clinicId, testListsId, props.startPoint);
  
     return navigateTo(
       localeUrlQuery({
         path: "/checkout",
       })
     );
    }
  } catch (error) {
   // console.log(error)
  }
}
</script>
