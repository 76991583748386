<template>
  <div class="backdrop">
    <div class="relative w-full select-none">
      <div
        @click.stop="isOpen = !isOpen"
        ref="dropdown"
        class="h-10 text-secondary font-bold flex justify-between relative base-selectinput z-10 items-center pl-2  cursor-pointer text-sm"
      >
        {{ placeholder }}
        <ChevronDownIcon
          class="w-5"
          :class="{
            'rotate-180': isOpen,
          }"
        />
      </div>
  
       <div  v-if="indexOf == 'categories' && filterStore['subcategory'].length !== 0" v-html="filterStore['subcategory'].length + filterStore['category'].length"
       class="bg-secondary w-5 h-5 z-20 rounded-full flex justify-center items-center text-white absolute -top-2 -right-1 text-[9px]"/>
      <div
        v-else-if="selectedItems.length !== 0 && !options"
        v-html="selectedItems.length"
        class="bg-secondary w-5 h-5 z-20 rounded-full flex justify-center items-center text-white absolute -top-2 -right-1 text-[9px]"
      />
      <div
        v-if="isOpen"
        ref="filter"
        class="absolute top-[48px] bg-white rounded-lg border border-secondary shadow-md z-[44444445]"
      >
        <!-- default select with option  -->
        <div v-if="options" class="flex flex-col w-52">
          <div
            v-for="item in options"
            :key="item"
            class="text-sm hover:bg-secondary hover:text-white py-2 px-3 cursor-pointer select-none"
            @click="onClicked(item)"
            
          >
            <span>{{ item.text }}</span>
          </div>
        </div>
        <!--  checkbox filter options  -->
        <div v-else class="px-4 py-6">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { onClickOutside } from "@vueuse/core";
const isOpen = ref(false);
const dropdown = ref(null);
const filterStore = useProductStore();
const props = defineProps({
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  options: {
    type: Array,
  },
  Items: {
    type: Array,
    default: [],
  },
  indexOf: String,
  customClass: String,
  selectedItems: {
    type: Array,
    default: [],
  },
});
const filter = ref(null);
const selected = ref(props.placeholder);
const emit = defineEmits(['onClick'])
onClickOutside(filter, () => { close(); });
const close = () => {
  let el = dropdown.value;
  isOpen.value = false;
};

// onMounted(() => {
//  window.addEventListener("click", documentClick);
// });
//onBeforeUnmount(() => {
  //window.removeEventListener("click", documentClick);
//})
const documentClick = (e) => {

  let el = dropdown.value;
  let target = e.target;
  if (el !== target && !el.contains(target)) isOpen.value = false;
};

function onClicked(data) {

  selected.value = data.text;
  isOpen.value = false;
  emit('onClick', data);
}

</script>
