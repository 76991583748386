<template>
  <div class="flex justify-center md:justify-end">
    <div>
      <div
        class="flex justify-end md:items-start items-center space-x-4 mt-7 md:mt-7"
      >
        <div v-if="filterSideBarOpen" class="relative overflow-hidden">
          <ProductMobileFilterSideBar
            :is-visible="filterSideBarOpen"
            :filters="filterOptions"
            @on-side-bar-close="closeFilterSideBar"
            :results-number="filterStore.filtredTests.length"
          />
        </div>
        <div class="flex lg:hidden">
          <FilterFloatButton
            @clicked="openFilterSideBar()"
            :selectedFilters="filterStore.checked"
          ></FilterFloatButton>
        </div>
        <div class="hidden lg:flex space-x-4">
          <FilterSelect
            :options="sortOptions"
            :placeholder="`${$t('filter.sortBy')}`"
           
            @onClick="setSortOption"
          />
          <!-- Filter By category -->
          <div
            v-if="filterOptions.categories.length > 1 && path[0] !== 'get-started'">
          <FilterSelect
            :placeholder="`${$t('filter.testCategory')}`"
            index-of="categories"
            :selected-items="filterStore.category"
          >
            <FilterCollectionMethods
              :Items="filterOptions.categories"
              indexOf="category"
            />
          </FilterSelect>
          
          </div>
          <!-- Filter by service types   -->
          <!-- <div v-if="path[0] !== 'get-started'">
            <FilterService />
          </div> -->

          <!-- Filter By Infections  -->
          <FilterSelect
            :placeholder="`${$t('filter.testsFor')}`"
            :selected-items="filterStore.infection"
          >
            <div class="flex flex-col -mt-5 md:mt-0 md:space-y-6 w-[270px]">
              <FilterInfection :infections="filterOptions.infections" />
            </div>
          </FilterSelect>

            <!-- Filter by Test Type  -->
            <FilterSelect
            :placeholder="`${$t('filter.testType')}`"
            index-of="test_types"
            v-if="path[0] !== 'get-started'"
            :selected-items="filterStore.test_type"
          >
            <FilterCollectionMethods
              :Items="filterOptions.test_types"
              indexOf="test_type"
            />
          </FilterSelect>

          <!-- Filter By Collection Methods  -->
          <FilterSelect

            :placeholder="`${$t('filter.collectionMethod')}`"
            index-of="collections"

            :selected-items="filterStore.collection"
          >
            <FilterCollectionMethods
              :Items="filterOptions.collection_methods"
              indexOf="collection"
            />
          </FilterSelect>

        

          <!-- Filter by Price Range  -->
          <FilterSelect
            :placeholder="`${$t('filter.priceRange')}`"
          
            :selected-items="filterStore.min_price"
          >
            <div class="w-[300px]">
              <ProductFilterRangePrice
                isPanel
                :min="filterOptions.min_price"
                :max="filterOptions.max_price"
              />
            </div>
          </FilterSelect>
        </div>
        <!-- Filter By search. Name  -->
        <div class="relative">
          <BaseVSearchTextInput
            id="search-product"
            name="search-product"
            :label="`${$t('filter.searchText')}`"
            v-model="productSearch"
            @click.prevent="onClicked()"
            class="w-[204px]"
          />
          <button
            class="flex justify-center items-center bg-secondary text-white md:w-8 md:h-8 w-[40px] h-[40px] rounded-md absolute right-1 top-5 md:top-1 z-30"
          >
            <SearchIcon class="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useProductStore } from "@/stores/filter";
import { SearchIcon } from "@heroicons/vue/outline";
const filterStore = useProductStore();
const filterOptions = computed(() => filterStore.filterOptions);
const productSearch = ref("");
const filterSideBarOpen = ref(false);
const route = useRoute();
const path = route.path.split("/").splice(2, 1);
watch(productSearch, (val) => {
  filterStore.setSearchValue(val);
});
const props = defineProps({
  isPriceClear: {
    type: Boolean,
  },
});
const sortOptions = computed(() => filterStore.getSortOptions);
const setSortOption = (item) => {
  filterStore.setSortOptionMobile(item);
};

const contentFilters = ref();
contentFilters.value = await useFilterOptions();
const onClicked = () => {
  if (productSearch.value == "") {
    filterStore.setSortOption(2);
  }
};
const openFilterSideBar = () => {
  document.body.classList.toggle("overflow-hidden");
  filterSideBarOpen.value = true;
};
const closeFilterSideBar = () => {
  document.body.classList.toggle("overflow-hidden");
  filterSideBarOpen.value = false;
};
</script>
