<template>
  <div
    v-show="!props.hidden"
    class="spinner"
    :style="{
      '--size': props.size,
      '--color': props.color,
      '--border-weight': props.borderWeight
    }"
  >
    <div
      class="spinner-track"
    ></div>
  </div>
</template>

<script setup lang="ts">
export interface SpinnerProps {
  hidden?: boolean;
  size?: string;
  color?: string;
  borderWeight?: string;
}

const props = defineProps<SpinnerProps>();
</script>

<style lang="scss" scoped>
.spinner {
  @apply relative inline-block self-center justify-self-center;
  width: var(--size, 1em);
  height: var(--size, 1em);

  & > .spinner-track {
    @apply absolute inset-1 rounded-[50%] border-solid animate-spin;
    border-color: var(--color, currentColor);
    border-top-color: transparent;
    border-width: var(--border-weight, 4px);
  }
}
</style>
