<template>
  <TransitionRoot appear :show="showModal" as="template">
    <Dialog as="div" @close="closeModal()" class="relative z-50 w-full">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-dark-blue-light" aria-hidden="true" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center w-full">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel id="modal" :class="fullscreen
    ? 'w-full h-[100vh]'
    : ' w-full max-w-[900px] min-h-[230px] h-auto rounded-lg'
    " class="w-full h-[100vh] md:max-h-full overflow-y-auto transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all mx-4 lg:mx-0">
              <div class="flex justify-center items-center absolute right-5 top-5">
                <button @click="closeModal()"
                  class="bg-[#D8D8D8] w-8 h-8 flex justify-center items-center rounded-full outline-0">
                  <XIcon class="w-6 h-6 text-secondary" />
                </button>
              </div>

              <div class="px-8 py-8 md:px-14 md:py-16">
                <h2 v-if="dataProp && dataProp != 'nurse_service_methods'" class="text-[27px] text-left leading-6 mb-8 md:mb-14">
                  {{ title }}
                </h2>
                <div v-if="dataProp == 'nurse_service_methods'" class="mb-4">
                  <div class="flex flex-col md:flex-row mb-8 ">
                    <NuxtImg loading="lazy" src="/icons/nurse-icon.svg" class="w-9 md:mx-0 mx-auto" alt="nurse icon" />
                    <h2 class="text-[27px] md:text-center md:mx-6 leading-10 ">

                      {{ title }}
                    </h2>
                  </div>
                  <div
                    class="bg-stone-white border border-[#00a1aa99] rounded-md opacity-100 px-6 md:px-12 py-4 mb-6 text-start">
                    <div class="mt-9 grid md:grid-cols-2 md:gap-x-32 gap-y-4">

                      <div v-for="(subOptions, index) in regionLists" :key="index">
                        <div class="flex py-3 border-b :last-border-b-0 border-b-primary-light">

                          <span class="text-sm text-start p-0 font-bold">
                            {{ subOptions.title }}
                          </span>
                          <div class="ml-auto">
                            + {{ currencyPrefix }}{{ subOptions.fee }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="dataProp == 'collection_methods'" v-for="(
                    collectionMethod, index
                  ) in descriptionList" :key="index" class="mb-4">
                  <span class="font-bold mb-2">
                    {{ collectionMethod.title }}
                  </span>
                  <div class="" v-html="collectionMethod.description"></div>
                </div>
                <div v-else class="" v-html="description"></div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/solid";
const currencyPrefix = useGlobalStore().siteCurrencySymbol;
const props = defineProps({
  showModal: {
    type: Boolean,
  },
  fullscreen: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
  },
  descriptionList: {
    type: Array,
  },
  dataProp: {
    type: String,
  },
  regionLists: {
    type: Array,
    default: [],
  }
});

const emit = defineEmits(["onModalClose"]);
const closeModal = () => {
  emit("onModalClose");
};
</script>
