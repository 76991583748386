<template> 
     <TransitionRoot as="template" :show="openDrawer">
      <Dialog as="div" class="relative z-50" @click="closeModal" >
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-dark-blue transition-opacity"
          :class="sliderOpacity" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 md:right-0  flex md:max-w-[390px]">
              <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from="translate-y-full md:translate-y-0 md:translate-x-full" enter-to="translate-y-0 md:translate-y-0  md:translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-y-0 md:translate-y-0  md:translate-x-0"
                leave-to="translate-y-full md:translate-y-0  md:translate-x-full">
                <DialogPanel class="pointer-events-auto relative w-screen md:max-w-[390px]">
                  <div class="absolute right-0 m-2 flex">
                    <button @click="closeModal" type="button"
                      class="z-50 text-gray-400 bg-light-silver rounded-full fill-secondary p-1">
                      <svg aria-hidden="true" class="w-5 h-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>
                     <slot> </slot>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
const emit = defineEmits(['closeModal']);
const props = defineProps({
  openDrawer: {
    type: Boolean,
    default: false
  },
  sliderOpacity: {
   type:  String,
   default: 'bg-opacity-60'
  }
})
const open = ref(props.openDrawer)

function closeModal() {
  emit('closeModal')
}


</script>
