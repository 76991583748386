<template>
  <div class="flex items-center py-4 w-full relative group">
    <input :aria-labelledby="id" type="radio" :id="id" :value="slug" v-model="modelValue" class="accent-primary w-4 h-4 peer checkbox appearance-none 
        checked:ring-1 checked:ring-primary border-dark-gray
        group-focus:outline-none border rounded-full  cursor-pointer
        checked:border-none mx-[14px] " />
    <div class="peer-checked:flex hidden bg-primary  absolute left-[18px] rounded w-2 h-2 z-1"></div>
    <label :for="id" class="font-semibold w-full flex absolute pl-10 pr-[14px] py-4 
        peer-checked:bg-gradient-to-b peer-checked:from-primary-lighter peer-checked:to-primary-lightest 
        group-focus:bg-gradient-to-b group-focus:from-primary-lighter group-focus:to-primary-lightest 
        group-hover:bg-gradient-to-b group-hover:from-primary-lighter group-hover:to-primary-lightest">
       <div :class="isEverywhere?'text-secondary':''"> {{ title }} </div>
      <span class="font-bold ml-auto rtl:mr-auto rtl:ml-0 whitespace-nowrap" :class="isEverywhere?'text-primary':''"> {{ extraText }} </span>
    </label>
  </div>
</template>

<script setup>
const props = defineProps({
  id: {
    type: Number,
    default: ''
  },
  modelValue: {
    type: String,
    default: 'peace-of-mind'
  },
  extraText: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  slug: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  isEverywhere: {
    type: Boolean
  }

});

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
})
</script>