<template>
  <VForm :validation-schema="schema" v-slot="{ handleSubmit }" as="div">
    <form
      @submit="handleSubmit($event, onSubmit)"
      class="space-y-8 flex flex-col"
    >
      <BaseVTextInput
        name="city"
        id="city"
        :label="`${$t('form.enterCity')}`"
        class="h-12"
      />

      <div class="flex items-center space-x-2">
        <BaseVTextInput
          id="postcode"
          name="postcode"
          :label="`${$t('form.enterPostCode')}`"
          class=""
        />
        <BaseButton type="submit" text="search" class="h-10">
          <NuxtImg src="/icons/search-white.svg" alt="search" />
        </BaseButton>
      </div>

      <CommonProductModal
        @on-modal-close="closeProductModal"
        :regionLists="regionLists.data"
        :title="$t('testDetail.feeRegion')"
        dataProp="nurse_service_methods"
        :showModal="showModal"
      />
      <NuxtLink
        class="underline text-secondary cursor-pointer"
        @click="onClick"
      >
        <div class="flex mt-4 items-baseline">
          <NuxtImg
            loading="lazy"
            src="/icons/c-info-e.svg"
            class="mx-1 align-top"
            alt="down arrow icon"
            width="16"
            height="16"
          />
          <span>
            {{ $t("testDetail.modalPrice") }}
          </span>
        </div>
      </NuxtLink>
    </form>
  </VForm>
</template>
<script setup>
import * as yup from "yup";
const { t } = useI18n();
const props = defineProps({
  regionLists: {
    type: Object,
    default: {},
  },
});

const schema = yup.object({
  city: yup
    .string()
    .required(t("validationErrors.cityRequired"))
    .label(t("form.enterCity")),
});

const emit = defineEmits(["onSubmit"]);
const showModal = ref(false);
function onSubmit(values) {
  const { city, postcode } = values;
  postcode ? emit("onSubmit", city + "," + postcode) : emit("onSubmit", city);
}

function onClick() {
  showModal.value = true;
}



function closeProductModal() {
  showModal.value = false;
}
</script>
