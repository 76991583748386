<template>
  <div class="flex items-center leading-7 font-bold my-8">
    <span class="mx-3">{{ $t("common.viewAs") }}</span>
    <div class="ml-auto flex items-center">
      <span class="mx-3">{{ $t("common.list") }}</span>
      <BaseButtonToggle
        buttonSize="large"
        :tabValue="tabValue"
        @onChange="onTabChange"
      ></BaseButtonToggle>
      <span class="mx-3">{{ $t("common.map") }}</span>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  tabValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["onTabChange"]);

function onTabChange(value) {
  emit("onTabChange", value);
}
</script>
