<template>
<div class="border-b-2 pb-8 pt-2 w-full mx-auto my-4"> 
  <div class="animate-pulse flex space-x-4 px-4">
    <SkeletonImage></SkeletonImage>
    <div class="flex-1 space-y-6 py-1">
      <div class="space-y-4">
        <SkeletonBase></SkeletonBase>
         <div class="grid grid-cols-3 gap-4">
          <SkeletonBase classStyle="h-4 col-span-2"></SkeletonBase>  
        </div>
      </div>
      <SkeletonBase classStyle="h-8 mt-12 w-24"></SkeletonBase>  
    </div>
  </div>
</div>
</template>

