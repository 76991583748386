<template>
  <div class="flex flex-col w-full bg-stone-white border border-[#00A1AA99] rounded p-5">
    <div v-if="showTestLists.length > 0">
       <BaseImageLink imageLink="c-info.svg" class="mb-6"
        v-if="showTestLists.length == maxTestList"
        :title="$t('checkoutMultipleTests.maxTestsTitle', {maxTests: maxTestList})">
       </BaseImageLink>
     
      <h4
        class="text-start font-bold text-primary"
      >{{ $t("checkoutMultipleTests.chosenTest") }}</h4>
      <div
        v-for="(item, key) in showTestLists"
        :key="key"
        :class="{'border-b border-b-primary-light':showTestLists.length > 1}"
        class="flex items-center w-full py-2 px-1"
      >
        <NuxtImg src="/icons/circle-ctrl-right.svg" class="w-3 mr-1" alt="ctrl-right" />
        <span class="text-sm text-start font-semibold">{{ item.title }}</span>
        <div class="ml-auto" v-if="showTestLists.length > 1">
          <NuxtImg
            src="/icons/delete-forever.svg"
            class="w-3 h-3 cursor-pointer"
            @click="onRemove(item)"
            alt="delete"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex items-center w-full py-1">
      <BaseImageLink imageLink="c-remove.svg" 
     
        :title="$t('checkoutMultipleTests.noTests')">
       </BaseImageLink>
     


      
    </div>

    <div v-if="getClinicDetail" class="mt-5">
      <h4
        class="text-start font-bold text-primary"
      >{{ $t("checkoutMultipleTests.chosenClinic") }}</h4>
      <span class="text-sm text-start font-semibold">{{ getClinicDetail.title }}</span>
    </div>
  </div>
</template>
<script setup>
import { useCheckoutStore } from "~/stores/checkout";
const checkoutStore = useCheckoutStore();
const props = defineProps({
  showTestLists: {
    type: Array
  },
  getClinicDetail: {
    type: Object
  }
});
const emit = defineEmits(["onTabChange"]);
const maxTestList = checkoutStore.maxTestLists;
function onRemove(item) {
  checkoutStore.setCollection(item);
}
</script>
