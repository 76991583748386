<template>
  <div :class="{ 'mx-4 md:mx-0': !props.disableDefaultCss }"  ref="clinicList">
    <label class="relative group block mb-4" v-if="showSearchBox">
      <button
        :type="props.buttonType"
        class="absolute right-0 rtl:right-auto rtl:left-0 flex items-center rounded m-1 transition-all group-focus:bg-primary disabled:opacity-50"
        @click="onSearchBoxClick"
        :class="showClose ? 'bg-primary' : 'bg-secondary'"
        :disabled="disabled"
      >
        <NuxtImg
          loading="lazy"
          src="/icons/search-white.svg"
          class="p-2 pointer-events-none"
          alt="search icon"
          width="42"
          height="42"
          v-if="!props.loading"
        />
        <CommonSpinner v-else color="#FFFFFF" size="42px" border-weight="4px" />
      </button>
      <span
        class="absolute top-3 right-12 rtl:right-auto rtl:left-12 bg-light-silver rounded-full fill-dark-blue p-0.5 mx-1 flex items-center"
        @click="onClose"
        :class="showClose ? 'visible' : 'invisible'"
      >
        <svg
          aria-hidden="true"
          class="w-5 h-5"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </span>

      <input
        class="block w-full border rounded-md py-2.5 focus:outline-none px-3 shadow-default text-blog-19 leading-7"
        :class="showClose ? 'border-primary' : 'border-secondary'"
        :placeholder="$t('clinic.search')"
        type="text"
        :disabled="disabled"
        :name="props.name"
        ref="mapSearch"
        v-model="searchText"
        @keyup.enter="onSearchBoxClick"
        @input="onFocus"
      />
    </label>
  </div>
</template>

<script setup>
const props = defineProps({
  showClose: {
    type: Boolean,
  },
  showSearchBox: {
    type: Boolean,
    default: true,
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
  tabValue: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: false,
    default: "search",
  },
  resetScroll: {
    type: Boolean,
    required: false,
    default: true,
  },
  buttonType: {
    type: String,
    required: false,
    default: "button",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableDefaultCss: {
    type: Boolean,
    required: false,
    default: false,
  },
  searchTextValue: {
    type:String,
    default: ""
  }
});


const searchText = ref(props.searchTextValue);
const disabled = computed(() => props.disabled || props.loading);

const mapSearch = ref("");
const emit = defineEmits(["onTabChange", "onSearchClick", "onCloseClick"]);

const showClose = computed({
  get: () => props.showClose,
  set: (value) => {
    // emit('showClose', value);
  },
});
function onClose() {
  if (props.showClose) {
    searchText.value = "";
  }
  emit("onCloseClick", searchText.value);
}
const clinicList = ref(null);
function onSearchBoxClick() {
  mapSearch.value.blur();
  emit("onSearchClick", searchText.value);
  if (props.resetScroll) {
  clinicList.value.scrollTop = 0;
  }
 // document.getElementById("clinicList").scrollTop = 0;
}


function onFocus() {
  if (searchText.value == "" && props.showClose) {
    emit("onCloseClick");
  }
}

function onChange(value) {
  emit("onTabChange", value);
}
</script>
