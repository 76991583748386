import { defineStore } from "pinia";
import type { GetStartedService } from "~/components/get-started/types";

// main is the name of the store. It is unique across your application
// and will appear in devtools

interface SelectedEntity {
  title: string;
  slug: string;
  content: string;
  clinic_view: string;
}

interface GetStartedStore {
  isOpen: boolean;
  enableMap: boolean;
  stepIndex: string;
  selectedEntity?: SelectedEntity;
  selectedService?: GetStartedService;
}

export const useGetStartedModalStore = defineStore("get-started", {
  state: () =>
    ({
      isOpen: false,
      enableMap: false,
      stepIndex: "select_clinic"
    } as GetStartedStore),

  actions: {
    open(entity?: GetStartedStore["selectedEntity"]) {
      this.isOpen = true;
      this.enableMap = true;

      if (entity) this.setSelectedEntity(entity);
    },
    
    close() {
      this.isOpen = false;
      this.enableMap = false;
    },

    setSelectedEntity(entity: GetStartedStore["selectedEntity"]) {
      this.selectedEntity = entity;
    },

    setSelectedService(service: GetStartedStore['selectedService']) {
      this.selectedService = service;
    },

    navigate(step: GetStartedStore["stepIndex"]) {
      this.stepIndex = step;
    },
  },
});
