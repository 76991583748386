<template>
  <div>
    <div
      class="flex px-3.5 md:px-4 py-6 items-start border-b-2 border-light-silver"
    >
      <div class="flex w-16">
        <NuxtImg
          loading="lazy"
          src="/icons/clinic-icon.svg"
          class="w-9"
          alt="clinic icon"
          width="36"
          height="36"
        />
      </div>
      <div class="flex flex-col w-full">
        <div class="flex items-center">
          <svg
            id="pin-3"
            xmlns="http://www.w3.org/2000/svg"
            width="12.4"
            height="16.412"
            viewBox="0 0 12.4 16.412"
            class="fill-secondary mx-1"
          >
            <path
              id="Path_91"
              data-name="Path 91"
              d="M13.2,1A6.14,6.14,0,0,0,7,7.2c0,4.116,5.7,9.863,5.942,10.105a.365.365,0,0,0,.516,0C13.7,17.063,19.4,11.316,19.4,7.2A6.14,6.14,0,0,0,13.2,1Zm0,8.389A2.188,2.188,0,1,1,15.389,7.2,2.191,2.191,0,0,1,13.2,9.389Z"
              transform="translate(-7 -1)"
            />
          </svg>
          <div class="basis-1/2 grow" @click="getTests(clinicList)">
            <h3 class="cursor-pointer">{{ clinicList.title }}</h3>
          </div>
        </div>
        <p class="text-blog-14-sm py-4">{{ clinicList.content }}</p>
        <BaseButton
          @buttonClicked="getTests(clinicList)"
          class="w-40 mt-4 font-bold"
          :text="$t('common.btnSelect')"
        ></BaseButton>
      </div>
      <div class="flex w-20 text-xs leading-[14px] mt-2">
        <span>{{ Number(clinicList.distance).toFixed(1) }} {{ $t("distance." + distanceUnit) }}</span>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { useMapStore } from "@/stores/map";
import { useCheckoutStore } from "~/stores/checkout";
import { useProductStore } from "~/stores/filter";
import { useGetStartedModalStore } from "~/stores/get-started-modal";
import { useGlobalStore } from "~/stores/global";
const router = useRouter();
const path = "/get-started";
const globalStore = useGlobalStore();
const mapStore = useMapStore();
const getStartedStore = useGetStartedModalStore();
const productStore = useProductStore();
const checkoutStore = useCheckoutStore();
const localePath = useLocalePath();
const locale = computed(() => globalStore.siteCurrentLocale);
const route = useRoute();

const distanceUnit = ref(globalStore.siteSettings.distance_unit.value);

const props = defineProps({
  color: {
    type: String,
    default: "primary/10",
  },
  clinicList: {
    type: Object,
  },
});

const getTests = async (item) => {
  productStore.clearAllFilters();
  const pathWithoutLocale = route.path
    .replace(`/${locale.value}`, "")
    .split("/")[1];

  switch (pathWithoutLocale) {
    case "get-started":
      await navigateTo({
        query: {
          ...route.query,
          slug: item.slug,
          ...(location.content ?? { content: location.content }),
        },
      });
      getStartedStore.navigate("show_tests");
    break;

    case "tests":
    const { data: clinicDetail } = await useFetchClinicDetailBySlug(item.slug);
    if(clinicDetail.value) {
    let clinicFormatedDetail = '';
     clinicFormatedDetail={
        id: clinicDetail.value.data.id,
        title: clinicDetail.value.data.display_title,
        slug: clinicDetail.value.data.slug
      };
     mapStore.setSelectedClinic(clinicFormatedDetail)
   }
 
   checkoutStore.removeTestPanelLists(productStore.selectedTest)


   return navigateTo(
        localeUrlQuery({    
          query: {
            ...route.query,
            slug: item.slug
        
          },
        })
      );
    break;
    case "clinics":
      mapStore.openPanel();
      navigateTo(
        localeUrlQuery({
          query: {
            ...route.query,
            slug: item.slug,
            ...(location.content ?? { content: location.content }),
          },
        })
      );
      break;
    default:
      checkoutStore.setSelectedClinicCookieData(item.id, productStore.selectedTest, 'test-page');
      navigateTo(
        localeUrlQuery({
          path: "/checkout",
          query: {
            clinic_id: item.id,
            test_id: checkoutStore.getCheckoutSession.test_id,
            start_point: "test-page",
          },
        })
      );
      break;
  }
};

</script>
<style scoped>
.modal-header {
  box-shadow: 0px 3px 6px #00000029;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
}
</style>
