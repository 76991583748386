<template>
  <div
    class="flex shadow-default bg-white w-auto break-words md:max-w-[40rem] z-30 top-0.5 md:absolute border -mb-8 md:-mb-3 space-x-4 md:left-24 rounded p-4 border-[#0000001A]"
  >
    <div class="flex flex-shrink-0">
      <NuxtImg
        loading="lazy"
        :src="clinicIcon"
        class="w-9 h-fit"
        alt="clinic-icon"
        width="36"
        height="36"
      />
    </div>
    <div class="flex flex-col justify-center">
      <div class="font-bold">
        {{ item.title }}
      </div>
      <p class="text-blog-14-sm" v-if="!!description">{{ description }}</p>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: {},
  },
})
const clinicIcon = computed(() => {
  if (props.item.slug == "instant-test") {
    return "/icons/clinic-instant-tests-icon-green.svg"
  } else if (props.item.slug == "everywhere") {
    return "/icons/nurse-icon-green.svg"
  }
  return "/icons/clinic-icon-green.svg"
});

const description = computed(() => {
  if (props.item.content === "*") return undefined
  return props.item.content
})
</script>
