<template>
  <label class="inline-flex relative items-center cursor-pointer">
    <input type="checkbox" v-model="modelValue" class="sr-only peer" />
    <div
      class="peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-primary after:content-[''] after:absolute shadow-[inset_0px_0px_8px_rgba(0,0,0,0.25)] after:bg-primary after:rounded-full after:transition-all border-[#0000001A]"
      :class="sizeClasses"
    ></div>
  </label>
</template>

<script setup>
const props = defineProps({
  tabValue: {
    type: Boolean,
    default: false,
  },
  buttonSize: {
    type: String,
    default: "large",
  },
});

const emit = defineEmits(["update:modelValue", "onChange"]);
const sizeClasses = computed(() => {
  if (props.buttonSize === "small") {
    return "w-10 h-5 after:h-5 after:w-5";
  }
  return "w-20 h-10 after:h-10 after:w-10";
});
const modelValue = computed({
  get: () => props.tabValue,
  set: (value) => {
    emit("onChange", value);
  },
});
</script>
