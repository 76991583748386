<template>
  <TransitionRoot as="template" :show="openDrawer" @click.prevent="closeModalBox">
    <Dialog as="div" class="relative z-40 " static
    @close="() => {}" :class="{ 'z-50': showFullPage }">
      <div class="fixed overflow-hidden top-32">
        <div class="absolute overflow-hidden top-32 bottom-0">
          <div
            class="pointer-events-none fixed  top-0 md:right-0 flex h-full"
            :class="{ 'md:top-16 top-14': !showFullPage }"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-y-0 translate-x-full"
              enter-to="translate-y-0  translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-y-0  translate-x-0"
              leave-to="translate-y-0  translate-x-full"
            >
              <DialogPanel class="pointer-events-auto relative w-screen">
                <slot> </slot>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
const emit = defineEmits(["closeModal"]);
const props = defineProps({
  openDrawer: {
    type: Boolean,
    default: false,
  },
  showFullPage: {
    type: Boolean,
    default: false,
  },
});

function closeModalBox() {
  emit("closeModal");
}
</script>
